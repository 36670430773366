import { createGlobalStyle } from 'styled-components'
import './App.css'

export const sizes = {
  desktop: 1300,
  tablet: 1024,
  phone: 768,
}

export const device = Object.keys(sizes).reduce((acc, cur) => {
  acc[cur] = `(max-width: ${sizes[cur]}px)`
  return acc
}, {})
export default device

export const GlobalStyle = createGlobalStyle`

  * { box-sizing: border-box; }
  body {
    font-family: 'Montserrat', 'Helvetica Neue', Arial, Helvetica, Verdana,
      sans-serif;
    color: #222;
    letter-spacing: 0.2px;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    -webkit-font-smoothing: antialiased;
	   -moz-osx-font-smoothing: grayscale;
     text-rendering: optimizeLegibility;
     @media ${device.tablet} {
     
      }

    background-color:#404040!important;
}
body .MuiButton-contained {
background-color:#74cfff;
color:#f3f3f3;
border-radius:0;
}

body .MuiFormLabel-root {
color:#f3f3f3;
font-family:'Montserrat';
    margin-left: 0;
}
body .MuiFormHelperText-root.Mui-error {

}
.group .checkbox-text {
color:#f3f3f3;
font-family:'Montserrat';

}
.prev-btn {
  background-color:#f3f3f3;
  color:#404040;
}
body .MuiRadio-colorSecondary.Mui-checked {
  color:#74cfff;
}

body .MuiFormLabel-root.Mui-focused {
  color:#74cfff;
  font-size:18px;
}
body .MuiFormControl-root {
  font-family:'Montserrat';
  
    width:100%;
  
  margin-bottom:50px;
  color:#f3f3f3;
    input{
    &::-webkit-input-placeholder {
    font-family:'Montserrat';
  }
  }
}
body {
.MuiInput-underline:before {
border-bottom:2px solid #f3f3f3;
}
.MuiInput-underline:after {
border-bottom:2px solid #74cfff;
}
.MuiInputBase-input {
  padding:2px 0 20px 0;
  color:#f3f3f3;
   font-family:'Montserrat';
}
}

.cntr {
overflow:hidden;
margin-bottom:30px;

.form-check {
padding-left:10px;
}
}

.btn-radio {
  cursor: pointer;
      display: flex;
    float: none;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  float: left;
  -webkit-user-select: none;
  user-select: none;
  margin-left:0;
   
}
.btn-radio:not(:first-child) {
 
}
@media screen and (max-width: 768px) {
  .btn-radio {
  &:not(:first-child) {
    margin-left: 0;
    margin-top: 15px;
  }

  span {
    margin-left: 20px;
    max-width: 80%;
  }
}
}

.btn-radio {
  svg {
    fill: none;
    vertical-align: middle;

    circle {
      stroke-width: 2;
      stroke: #f3f3f3;
    }

    path {
      stroke: #4097db;

      &.inner {
        stroke-width: 6;
        stroke-dasharray: 19;
        stroke-dashoffset: 19;
      }

      &.outer {
        stroke-width: 2;
        stroke-dasharray: 57;
        stroke-dashoffset: 57;
      }
    }
  }

  input {
    display: none;

    &:checked + svg path {
      transition: all 0.4s ease;

      &.inner {
        stroke-dashoffset: 38;
        transition-delay: 0.3s;
      }

      &.outer {
        stroke-dashoffset: 0;
      }
    }
  }

  span {
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
  }
}

/* input[type='checkbox'] {
    opacity: 0;
    z-index: -1;
    display:none;
} */
.checkbox-row {
padding-left:10px;
display: flex;
    flex-flow: row wrap;
    justify-content:flex-start;
    margin-top:20px;
    .form-check {
      width:25%;
      margin-bottom:20px;
      @media screen and (max-width: 768px) {
        width:40%;
      }
      @media screen and (min-width: 1440px) {
      width:20%;
      }
    }
    .date-label {
    width: 100px;
   
     @media screen and (max-width: 768px) {
        width:70px;
         margin-right: 10px;
    margin-left: 10px;
      }
    }
    }
    
/* input[type='checkbox']+label {
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
   
    margin: auto;
    margin-left:0;
    margin-right:20px;
     @media screen and (max-width: 768px) {
     margin-right:0;
     }
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    text-indent: -999px;
    overflow: hidden;
    border: 1px solid #f3f3f3;
    cursor: pointer;
}

@mixin transition($time, $type, $property) {
    transition: $time $type $property;
    -moz-transition: $time $type $property;
    -webkit-transition: $time $type $property;
}

input[type='checkbox']+label {
    border: 1px solid #f3f3f3;
    background: transparent;
    @include transition(0.5s, ease, all);
}

input[type='checkbox']:checked+label {
    border: 1px solid #f3f3f3;
    background: white;
} */

.date-label {
    margin-right: 20px;
    margin-left: 20px;
    color:#f3f3f3;
    font-family:'Montserrat';
    font-weight:500;
}
  .hero {
  background-color:#f3f3f3;
  }
  .logo  {
  display:block;
  margin:auto;
  width:250px;
  img {
    max-width:100%;
    height: auto;
    margin-top: 20px;
  }
  }
  .intro {
  
  max-width:960px;
  margin:auto;
  padding:0 20px;
  p{
    color:#404040;
    margin-bottom:50px;
    font-family:"Montserrat";
  }
  }

 h1 {
    font-family:'Artigiano Display';
    font-weight:normal;
    color:#404040;
    margin-bottom:20px;
}
  
.heading-1 {
    max-width: 70%;
    margin-top: 50px;
}

 .form-control {
    background-color: #f3f3f3;
    background-clip: padding-box;
    font-family:'Montserrat';
    height:40px;
    border-radius:20px;
    padding: 10px 20px;
    border:2px solid white;
    color: #404040;
    font-weight:500;
    &:focus {
    color:#404040;
    background-color: #f3f3f3;
    border:2px solid #4097DB;
    offset:0;
    box-shadow:0 0 0 0;
}
}



label {
    color:#f3f3f3;
    font-family:'Montserrat';
    font-weight:500;
    margin-left: 20px;
}
 .form-group {
    position:relative;
    margin-bottom: 24px;
}
 a {
    color:#4097db;
}


button[type=submit].btn-secondary:not(:disabled){
    background-color:#4097db;
    border-radius:0;
    color:white;
    border:0;
    border-radius:20px;
    &:hover, &:active {
      background-color:#f3f3f3;
      color:#404040;
    }
}

@media only screen and (max-width:768px){
    button[type=submit]{
        width: 100%;
    }
    
}
@media only screen and (min-width:769px) {
    button[type=submit]{
        width: 250px;
    }
}

.form-control ~ .focus-border {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #4097db;
    transition: 0.4s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header-image {
    height: 350px;
    object-fit: cover;

}

@media only screen and (max-width:768px){
.header-image {
height: 100%;
    object-fit: contain;
}
}
header {
img{

  margin-bottom:25px;
  width:100%;
}
} 

form {
  padding-bottom:50px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.footer a {

  font-size:12px;
  color:#f3f3f3;
  margin: auto;
  display: block;
  margin-bottom:30px;
}
.checkbox {
  padding-left:10px;
}
.lead {
    font-size:18px;
}
}
     
`
