import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import { FieldArray, Formik, Field } from 'formik'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import * as yup from 'yup'
import Debug from './Debug'
const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
}
ReactPixel.init('2514128785373075', advancedMatching, options)
ReactPixel.pageView()
ReactGA.initialize('UA-132325441-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const FirstPage = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  telephone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required(),
})

const SecondPage = yup.object().shape({
  clientOption: yup.string().required('Please choose on of the options'),
  companyName: yup.string().when('clientOption', {
    is: 'pr',
    then: yup.string().required('Company name is required.'),
  }),
  carCollection: yup.string().when('clientOption', {
    is: 'buyer',
    then: yup.string().required('Current car collection is required.'),
  }),
})

const ThirdPage = yup.object().shape({
  dates: yup
    .array()
    .of(yup.string().min(2))
    .required('Choose at least one day'),
  gdpr: yup.bool().oneOf([true], 'Must agree to Privacy Policy'),
  newsletter: yup.bool(),
})

const dates = [
  '03.03.2020',
  '04.03.2020',
  '05.03.2020',
  '06.03.2020',
  '07.03.2020',
  '08.03.2020',
  '09.03.2020',
  '10.03.2020',
  '11.03.2020',
  '12.03.2020',
  '13.03.2020',
  '14.03.2020',
  '15.03.2020',
]

const clientOptions = [
  { question: "I'm a journalist", name: 'pr' },
  {
    question:
      'I am interested in owning the Battista hypercar and would like to meet a Sales Specialist',
    name: 'buyer',
  },
  {
    question:
      'I am interested in viewing Battista and learning more about Automobili Pininfarina',
    name: 'visitor',
  },
]

const Error = ({ name }) => (
  <Field name={name}>
    {({ form: { touched, errors } }) =>
      touched[name] && errors[name] ? (
        <span className="focus-border">{errors[name]}</span>
      ) : null
    }
  </Field>
)

class Wizard extends Component {
  static Page = ({ children, parentState }) => {
    return children(parentState)
  }

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues,
    }
  }

  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }))

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
    }))

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = (values, bag) => {
    const { children } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      const submitLead = values => {
        let data
        axios({
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          url: 'https://hooks.zapier.com/hooks/catch/4300857/om5pr02/',
          data: values,
        })
          .then(response => {
            this.setState({ message: 'success' })
            ReactPixel.track('CompleteRegistration')
            alert(
              ' Thank you for the request!\n' +
                '                            You will be contacted soon',
            )
            ReactGA.event({
              category: 'GenevaMotorShow2020',
              action: 'Lead',
            })
          })
          .catch(error => {
            this.setState({ message: 'failure' })
          })
          .then(() => {})
        return data
      }
      submitLead(values)
    } else {
      this.next(values)
      bag.setSubmitting(false)
    }
  }

  getValidationSchema = page => {
    const validationSchemas = [FirstPage, SecondPage, ThirdPage]
    return validationSchemas[page]
  }

  render() {
    const { children } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    console.log(activePage)

    const isLastPage = page === React.Children.count(children) - 1
    return (
      <Formik
        initialValues={values}
        validationSchema={this.getValidationSchema(this.state.page)}
        enableReinitialize={false}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        render={props => (
          <form onSubmit={props.handleSubmit} className={'mt-5 mb-3'}>
            {React.cloneElement(activePage, { parentState: { ...props } })}
            <div className="row mt-4">
              <Col md={12}>
                <div className={'d-flex justify-content-end'}>
                  {page > 0 && (
                    <Button
                      variant="contained"
                      onClick={this.previous}
                      className={'prev-btn'}
                    >
                      Previous
                    </Button>
                  )}

                  {!isLastPage && (
                    <Button type="submit" variant="contained">
                      Continue
                    </Button>
                  )}
                  {isLastPage && (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={props.isSubmitting}
                    >
                      Send Request
                    </Button>
                  )}
                </div>
              </Col>
            </div>
            {process.env.NODE_ENV === 'development' && <Debug />}
          </form>
        )}
      />
    )
  }
}

const Main = ({ campaign, source, medium, targetGroup, postType }) => {
  return (
    <div className={'intro'}>
      <Row>
        <Col>
          <Wizard
            initialValues={{
              campaign: campaign,
              source: source,
              medium: medium,
              targetGroup: targetGroup,
              postType,
              timeStamp: Date(),
              firstName: '',
              lastName: '',
              email: '',
              telephone: '',
              clientOption: '',
              carCollection: '',
              companyName: '',
              dates: [],
              gdpr: false,
              newsletter: false
            }}
          >
            <Wizard.Page>
              {props => {
                const { errors, touched } = props
                return (
                  <React.Fragment>
                    <Row>
                      <Col xs={12} md={6}>
                        <TextField
                          name="firstName"
                          helperText={
                            errors.firstName &&
                            touched.firstName &&
                            errors.firstName
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            props.touched.firstName &&
                            Boolean(props.errors.firstName)
                          }
                          label="First Name"
                          value={props.values.firstName}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <TextField
                          name="lastName"
                          helperText={
                            errors.lastName &&
                            touched.lastName &&
                            errors.lastName
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            props.touched.lastName &&
                            Boolean(props.errors.lastName)
                          }
                          label="Last Name"
                          value={props.values.lastName}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <TextField
                          name="email"
                          helperText={
                            errors.email && touched.email && errors.email
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            props.touched.email && Boolean(props.errors.email)
                          }
                          label="Email"
                          value={props.values.email}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <TextField
                          name="telephone"
                          helperText={
                            errors.telephone &&
                            touched.telephone &&
                            errors.telephone
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            props.touched.telephone &&
                            Boolean(props.errors.telephone)
                          }
                          label="Telephone"
                          value={props.values.telephone}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                )
              }}
            </Wizard.Page>
            <Wizard.Page>
              {({ errors, touched, ...props }) => (
                <React.Fragment>
                  <Row>
                    <Col xs={12} md={12}>
                      <FormLabel component="legend">
                        Tell us more about yourself
                      </FormLabel>
                      <RadioGroup aria-label="clientOption">
                        <FieldArray
                          name="clientOption"
                          render={arrayHelpers => (
                            <div>
                              {clientOptions.map(option => (
                                <div key={option.name}>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        name="clientOption"
                                        value={option.name}
                                        checked={
                                          props.values.clientOption ===
                                          option.name
                                        }
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                      />
                                    }
                                    label={option.question}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        />
                      </RadioGroup>
                      <Error name="clientOption" />
                    </Col>
                  </Row>
                  {props.values.clientOption === 'pr' && (
                    <Row>
                      <Col xs={12} md={6}>
                        <TextField
                          fullWidth
                          name="companyName"
                          helperText={errors.companyName && errors.companyName}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            touched.companyName && Boolean(errors.companyName)
                          }
                          label="Company / Organization Name"
                        />
                      </Col>
                    </Row>
                  )}
                  {props.values.clientOption === 'buyer' && (
                    <Row>
                      <Col xs={12} md={6}>
                        <TextField
                          fullWidth
                          name="carCollection"
                          helperText={errors.carCollection}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={Boolean(errors.carCollection)}
                          label="Current Car Collection"
                        />
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              )}
            </Wizard.Page>

            <Wizard.Page>
              {({ errors, touched, ...props }) => (
                <React.Fragment>
                  <Row>
                    <Col xs={12} md={12}>
                      <label>What dates are you visiting?</label>
                      <FieldArray
                        name="dates"
                        render={arrayHelpers => (
                          <div className={'checkbox-row'}>
                            {dates.map(date => (
                              <div key={date}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="dates"
                                      type="checkbox"
                                      value={date}
                                      checked={props.values.dates.includes(
                                        date,
                                      )}
                                      onChange={e => {
                                        if (e.target.checked)
                                          arrayHelpers.push(date)
                                        else {
                                          const idx = props.values.dates.indexOf(
                                            date,
                                          )
                                          arrayHelpers.remove(idx)
                                        }
                                      }}
                                    />
                                  }
                                  label={date}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      />
                      <Error name="dates" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="group">
                        <Checkbox
                          helperText={
                            errors.gdpr && touched.gdpr && errors.gdpr
                          }
                          type="checkbox"
                          name="gdpr"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={touched.gdpr && Boolean(errors.gdpr)}
                        />
                        <a href="https://automobili-pininfarina.com/privacy-policy/">
                          Accept our Privacy Policy
                        </a>
                          <Error name="gdpr" />
                      </div>
                        <div className="group">
                            <Checkbox
                                helperText={
                                    errors.newsletter && touched.newsletter && errors.newsletter
                                }
                                type="checkbox"
                                name="newsletter"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                error={touched.newsletter && Boolean(errors.newsletter)}
                            />
                            <span className={"checkbox-text"}>
                                I am interested in learning more about Automobili Pininfarina
                            </span>
                        </div>

                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </Wizard.Page>
          </Wizard>
        </Col>
      </Row>
    </div>
  )
}

export default Main
