import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'

import Footer from './Footer'
import Header from './Header'
import Main from './Main'
import { GlobalStyle } from './Styles'

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

const campaign = urlParams.get('utm_campaign') || ''
const source = urlParams.get('utm_source') || ''
const medium = urlParams.get('utm_medium') || ''
const targetGroup = urlParams.get('utm_anzeigengruppe') || ''
const postType = urlParams.get('utm_Anzeige') || ''

const App = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Header />
      <Main
        campaign={campaign}
        source={source}
        medium={medium}
        targetGroup={targetGroup}
        postType={postType}
      />
      <Footer />
    </React.Fragment>
  )
}

export default App
