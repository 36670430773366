import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Banner from './banner.jpg'
import Logo from './logo.png'

export const Footer = () => (
  <Container fluid className="footer">
      <Row >
          <img className={'header-image'} src={Banner} width="100%" />
          <Col>
          <a className={'logo'} href="https://automobili-pininfarina.com/">
              <img
                  className="preload-me"
                  src={Logo}
                  width="250"
                  height="100"
                  alt="PininFarina"
              />
          </a>
          </Col>
      </Row>
    <Row>
      <a className={'text-center'} href="https://automobili-pininfarina.com/">
        Copyright © 2020 Automobili Pininfarina
      </a>
    </Row>

  </Container>
)

export default Footer
